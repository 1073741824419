import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("companyDetails")
  ? JSON.parse(localStorage.getItem("companyDetails"))
  : {
      CompanyID: "",
      ExpiryDate: "",
      CompanyName: "",
      CountryName: "",
      CountryID: "",
      BussinesType: "",
      BusinessTypeName: "",
      Country: "",
      CurrencySymbol: "",
      State: "",
      StateName: "",
      State_Code: "",
      CompanyLogo: "",
      City: "",
      Address1: "",
      Address2: "",
      Address3: "",
      company_count: "",
      Edition: "",
      BranchID: "",
      BranchName: "",
      IsBranch: false,
      NoOfBrances: "",
      EnableZatca: false,
    };

const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState,
  reducers: {
    companyDetailsSuccess: (state, action) => {
      console.log(action.payload.BranchID, "ooooooopp");
      console.log(action.payload.BranchName, "ooooooopp");
      state.CompanyID = action.payload.CompanyID;
      state.ExpiryDate = action.payload.ExpiryDate;
      state.CompanyName = action.payload.CompanyName;
      state.CountryName = action.payload.CountryName;
      state.CountryID = action.payload.CountryID;
      state.CountryCode = action.payload.CountryCode;
      state.BussinesType = action.payload.BussinesType;
      state.BusinessTypeName = action.payload.BusinessTypeName;
      state.Country = action.payload.Country;
      state.CurrencySymbol = action.payload.CurrencySymbol;
      state.State = action.payload.State;
      state.StateName = action.payload.StateName;
      state.State_Code = action.payload.State_Code;
      state.CompanyLogo = action.payload.CompanyLogo;
      state.City = action.payload.City;
      state.Address1 = action.payload.Address1;
      state.Address2 = action.payload.Address2;
      state.Address3 = action.payload.Address3;
      state.company_count = action.payload.company_count;
      state.Edition = action.payload.Edition;
      state.BranchID = action.payload.BranchID;
      state.BranchName = action.payload.BranchName;
      state.IsBranch = action.payload.IsBranch;
      state.NoOfBrances = action.payload.NoOfBrances;
      state.EnableZatca = action.payload.EnableZatca;

      localStorage.setItem("companyDetails", JSON.stringify(action.payload));
    },
    companyDetailsLogoutSuccess: (state, action) => {
      state.CompanyID = null;
      state.ExpiryDate = null;
      state.CompanyName = null;
      state.CountryName = null;
      state.CountryID = null;
      state.CountryCode = null;
      state.BussinesType = null;
      state.BusinessTypeName = null;
      state.Country = null;
      state.CurrencySymbol = null;
      state.State = null;
      state.StateName = null;
      state.State_Code = null;
      state.CompanyLogo = null;
      state.City = null;
      state.Address1 = null;
      state.Address2 = null;
      state.Address3 = null;
      state.company_count = null;
      state.Edition = null;
      state.BranchID = null;
      state.BranchName = null;
      state.IsBranch = false;
      state.NoOfBrances = null;
      state.EnableZatca = false;

      localStorage.removeItem("companyDetails");
    },
  },
});

export const { companyDetailsSuccess, companyDetailsLogoutSuccess } =
  companyDetailsSlice.actions;

export const selectCompanyName = (state) => state.companyDetails.CompanyName;
export const selectCompanyID = (state) => state.companyDetails.CompanyID;
export const selectBranchID = (state) => state.companyDetails.BranchID;
// export const selectUserEmail = (state) => state.companyDetails.email;
// export const selectUserPhoto = (state) => state.companyDetails.photo;
// export const accessToken = (state) => state.companyDetails.access;
// export const selectAuthenticated = (state) => state.companyDetails.isAuth;

export default companyDetailsSlice.reducer;
