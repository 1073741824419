import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("userTable")
  ? JSON.parse(localStorage.getItem("userTable"))
  : {
      Cash_Account: "",
      Bank_Account: "",
      Sales_Account: "",
      Sales_Return_Account: "",
      Purchase_Account: "",
      Purchase_Return_Account: "",
      ExpiryDate: "",
      Warehouse_name: "",
      Employee_name: "",
      DefaultAccountForUser: "",
      is_owner: "",
      user_type: "",
      Sales_Account_name: "",
      Sales_Return_Account_name: "",
      Purchase_Account_name: "",
      Purchase_Return_Account_name: "",
      Cash_Account_name: "",
      Bank_Account_name: "",
      VAT_Type_name: "",
      Sales_GST_Type_name: "",
      Purchase_GST_Type_name: "",
    };

const userTableSlice = createSlice({
  name: "userTable",
  initialState,
  reducers: {
    userTableSuccess: (state, action) => {
      state.Cash_Account = action.payload.Cash_Account;
      state.Bank_Account = action.payload.Bank_Account;
      state.Sales_Account = action.payload.Sales_Account;
      state.Sales_Return_Account = action.payload.Sales_Return_Account;
      state.Purchase_Account = action.payload.Purchase_Account;
      state.Purchase_Return_Account = action.payload.Purchase_Return_Account;
      state.ExpiryDate = action.payload.ExpiryDate;
      state.Warehouse_name = action.payload.Warehouse_name;
      state.Employee_name = action.payload.Employee_name;
      state.DefaultAccountForUser = action.payload.DefaultAccountForUser;
      state.is_owner = action.payload.is_owner;
      state.user_type = action.payload.user_type;
      state.Sales_Account_name = action.payload.Sales_Account_name;
      state.Sales_Return_Account_name =
        action.payload.Sales_Return_Account_name;
      state.Purchase_Account_name = action.payload.Purchase_Account_name;
      state.Purchase_Return_Account_name =
        action.payload.Purchase_Return_Account_name;
      state.Cash_Account_name = action.payload.Cash_Account_name;
      state.Bank_Account_name = action.payload.Bank_Account_name;
      state.VAT_Type_name = action.payload.VAT_Type_name;
      state.Sales_GST_Type_name = action.payload.Sales_GST_Type_name;
      state.Purchase_GST_Type_name = action.payload.Purchase_GST_Type_name;

      localStorage.setItem("userTable", JSON.stringify(action.payload));
    },
    userTableLogoutSuccess: (state, action) => {
      state.Cash_Account = null;
      state.Bank_Account = null;
      state.Sales_Account = null;
      state.Sales_Return_Account = null;
      state.Purchase_Account = null;
      state.Purchase_Return_Account = null;
      state.ExpiryDate = null;
      state.Warehouse_name = null;
      state.Employee_name = null;
      state.DefaultAccountForUser = null;
      state.is_owner = null;
      state.user_type = null;
      state.Sales_Account_name = null;
      state.Sales_Return_Account_name = null;
      state.Purchase_Account_name = null;
      state.Purchase_Return_Account_name = null;
      state.Cash_Account_name = null;
      state.Bank_Account_name = null;
      state.VAT_Type_name = null;
      state.Sales_GST_Type_name = null;
      state.Purchase_GST_Type_name = null;

      localStorage.removeItem("userTable");
    },
  },
});

export const {
  userTableSuccess,
  userTableLogoutSuccess,
} = userTableSlice.actions;

// export const selectCompanyName = (state) => state.userTable.CompanyName;

export default userTableSlice.reducer;
