import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("language")
  ? JSON.parse(localStorage.getItem("language"))
  : { language: "en" };

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    languageSuccess: (state, action) => {
      state.language = action.payload.language;

      localStorage.setItem("language", JSON.stringify(action.payload));
    },
    languageLogoutSuccess: (state, action) => {
      state = initialState;

      localStorage.removeItem("language");
    },
  },
});

export const { languageSuccess, languageLogoutSuccess } = languageSlice.actions;

// export const selectCompanyName = (state) => state.language.CompanyName;

export default languageSlice.reducer;
