import { createSlice } from "@reduxjs/toolkit";
import { get_user_role_data } from "../../screens/UserRoleSettings/UserRoleJsons";

const initialState = localStorage.getItem("userRoleSettings")
  ? JSON.parse(localStorage.getItem("userRoleSettings"))
  : get_user_role_data;

const userRoleSettingsSlice = createSlice({
  name: "userRoleSettings",
  initialState,
  reducers: {
    userRoleSettingsSuccess: (state, action) => {
      console.log(action.payload);
      action.payload.map((i, index) => {
        state[index] = { ...i };
      });

      localStorage.setItem("userRoleSettings", JSON.stringify(action.payload));
    },
    userRoleSettingsLogoutSuccess: (state, action) => {
      state = initialState;
      localStorage.removeItem("userRoleSettings");
    },
  },
});

export const { userRoleSettingsSuccess, userRoleSettingsLogoutSuccess } =
  userRoleSettingsSlice.actions;

// export const selectUserName = (state) => state.userRoleSettings.name;
// export const selectUserEmail = (state) => state.userRoleSettings.email;
// export const selectUserPhoto = (state) => state.userRoleSettings.photo;
// export const accessToken = (state) => state.userRoleSettings.access;
// export const selectAuthenticated = (state) => state.userRoleSettings.isAuth;

export default userRoleSettingsSlice.reducer;
