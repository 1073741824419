import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("submitMode")
  ? JSON.parse(localStorage.getItem("submitMode"))
  : { sales_submit: 1 };

const submitModeSlice = createSlice({
  name: "submitMode",
  initialState,
  reducers: {
    submitModeSuccess: (state, action) => {
      console.log("here we are pppppppppppppppp");
      console.log(action.payload);
      state.sales_submit = action.payload.sales_submit;
      state.sales_print_copies = action.payload.sales_print_copies;
      localStorage.setItem("submitMode", JSON.stringify(action.payload));
    },
    submitModeLogoutSuccess: (state, action) => {
      state = initialState;

      localStorage.removeItem("submitMode");
    },
  },
});

export const {
  submitModeSuccess,
  submitModeLogoutSuccess,
} = submitModeSlice.actions;

// export const selectCompanyName = (state) => state.submitMode.CompanyName;

export default submitModeSlice.reducer;
