import React from "react";
import styled from "styled-components";

const Loader = (props) => {
  return (
    <Container load={props.load}>
      <ImageContainer>
        <Image src="../images/icons/Logo.svg" />
      </ImageContainer>
    </Container>
  );
};

export default Loader;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  ${({ load }) =>
    load === true &&
    `
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 10000;
  `}

  ${({ load }) =>
    load === false &&
    `
display: none;
  `}
`;
const Image = styled.img`
  width: 100%;
`;
const ImageContainer = styled.div`
  width: 75px;
  height: 75px;
  padding: 5px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0c0c0c;
  border-radius: 50px;
  animation: example 3s infinite;

  @keyframes example {
    0%,
    100% {
      width: 50px;
      height: 50px;
      /* width: 75px; */
      opacity: 0;
      /* transform: rotateY(90deg); */
    }
    50% {
      width: 75px;
      height: 75px;
      opacity: 1;
      /* transform: rotateY(360deg); */
    }
  }
`;
