import { useEffect } from "react";

export function checkWordLength(word) {
  let str = new String(word);
  let length = str.length;
  if (length > 13) {
    return true;
  }
  return false;
}

export function Page(props) {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);
  return props.children;
}
