import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("versions")
  ? JSON.parse(localStorage.getItem("versions"))
  : {
      VersionNo: "",
      IsUpdationWindowClosed:false
    };

const versionSlice = createSlice({
  name: "versions",
  initialState,
  reducers: {
    versionSliceSuccess: (state, action) => {
      state.VersionNo = action.payload.VersionNo;
      state.Message = action.payload.Message;
      state.is_updation = action.payload.is_updation;
      state.show_center_menu = action.payload.show_center_menu;
      state.IsUpdationWindowClosed=action.payload.IsUpdationWindowClosed
      localStorage.setItem("versions", JSON.stringify(action.payload));
    },
  },
});

export const { versionSliceSuccess } = versionSlice.actions;
export const selectVersionNo = (state) => state.versionDetails.VersionNo;

export default versionSlice.reducer;
