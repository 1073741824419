import "./App.css";
import React, { lazy, Suspense, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import CacheBuster from "./CacheBuster";
import Loader from "./api/Loader";
import { Page } from "./screens/navigation/functions";
import { getCookie } from "./functions/utils";
import LandingPage from "./screens/home/LandingPage";

const Home = lazy(() => import("./screens/home/Home"));
const CreateCompany = lazy(() =>
  import("./screens/organization/CreateOrganization")
);
const SignIn = lazy(() => import("./screens/auth/SignIn"));
const SignUp = lazy(() => import("./screens/auth/SignUp"));
const LoginAdmins = lazy(() => import("./screens/auth/LoginAdmins"));
const Navigation = lazy(() => import("./screens/navigation/Navigation"));
const ServiceRedirect = lazy(() =>
  import("../src/screens/service/ServiceRedirect")
);

const ForgotPassword = lazy(() => import("./screens/auth/ForgotPassword"));
const ForgotPasswordConfirm = lazy(() =>
  import("./screens/auth/ForgotPasswordConfirm")
);
const SignUpVerification = lazy(() =>
  import("./screens/auth/SignUpVerification")
);
const HomeDemo = lazy(() => import("./screens/home/HomeDemo"));
const PageNotFound = lazy(() => import("./screens/home/PageNotFound"));
const EmailVerification = lazy(() =>
  import("./screens/auth/EmailVerification")
);
const QRInvoice = lazy(() => import("./screens/extra/QRInvoice"));
const SendVerification = lazy(() => import("./screens/auth/SendVerification"));
const Privacy = lazy(() => import("./screens/home/Privacy"));
const ExploreMore = lazy(() => import("./screens/home/ExploreMore"));
const OurBlog = lazy(() => import("./screens/home/OurBlog"));
const ArticleSearch = lazy(() => import("./screens/home/components/ArticleSearch"));
const Support = lazy(() => import("./screens/home/Support"));
const InvoiceReport = lazy(() => import("./screens/home/InvoiceReport"));

// import { stopReportingRuntimeErrors } from "react-error-overlay";

// if (process.env.NODE_ENV === "development") {
//   stopReportingRuntimeErrors(); // disables error overlays
// }
  

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <Suspense fallback={<Loader />}>
            <Router>
              <Switch>
                <Route
                  exact
                  path="/"
                  // component={Home}
                  render={(props) => (
                    <Page title="Viknbooks">
                      {/* <Home {...props} /> */}
                      <LandingPage />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/privacy"
                  // component={Privacy}
                  render={(props) => (
                    <Page title="Privacy Policy">
                      <Privacy {...props} />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/home-demo"
                  // component={HomeDemo}
                  render={(props) => (
                    <Page title="Demo">
                      <HomeDemo {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/signin"
                  // component={SignIn}
                  // render={(props) => (
                  //   <Page title="Signin">
                  //     <SignIn {...props} />
                  //   </Page>
                  // )}
                  component={ServiceRedirect}
                />
                <Route
                  path="/LoginAdmins"
                  // component={LoginAdmins}
                  // render={(props) => (
                  //   <Page title="LoginAdmins">
                  //     <LoginAdmins {...props} />
                  //   </Page>
                  // )}
                  component={ServiceRedirect}
                />
                <Route
                  path="/signup"
                  // component={SignUp}
                  // render={(props) => (
                  //   <Page title="Signup">
                  //     <SignUp {...props} />
                  //   </Page>
                  // )}
                  component={ServiceRedirect}
                />
                <Route
                  path="/forgot-password"
                  // component={ForgotPassword}
                  // render={(props) => (
                  //   <Page title="Forgot Password">
                  //     <ForgotPassword {...props} />
                  //   </Page>
                  // )}
                  component={ServiceRedirect}
                />
                <Route
                  path="/resend-verification"
                  // component={SendVerification}
                  // render={(props) => (
                  //   <Page title="Resend Verification">
                  //     <SendVerification {...props} />
                  //   </Page>
                  // )}
                  component={ServiceRedirect}
                  
                />
                <Route
                  path="/forgot-password-confirm/:uidb64-:token"
                  // component={ForgotPasswordConfirm}
                  render={(props) => (
                    <Page title="Password Confirm">
                      <ForgotPasswordConfirm {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/signUp-verification/:id/:token"
                  // component={SignUpVerification}
                  render={(props) => (
                    <Page title="SignUp Verification">
                      <SignUpVerification {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/email-verification/:id"
                  // component={EmailVerification}
                  render={(props) => (
                    <Page title="Email Verification">
                      <EmailVerification {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/invoice/:id/:type/"
                  // component={QRInvoice}
                  render={(props) => (
                    <Page title="Invoice">
                      <QRInvoice {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/create-company"
                  // component={CreateCompany}
                  render={(props) => (
                    <Page title="Create Company">
                      <CreateCompany {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/dashboard"
                  //  component={Navigation}
                  render={(props) => (
                    <Page title="Navigation">
                      <Navigation {...props} />
                    </Page>
                  )}
                />
                <Route path="/service" component={ServiceRedirect} />

                <Route
                  path="/explore-more"
                  // component={ExploreMore}
                  render={(props) => (
                    <Page title="Explore More">
                      <ExploreMore {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/our-blog"
                  // component={OurBlog}
                  render={(props) => (
                    <Page title="Our Blog">
                      <OurBlog {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/article-search/:id"
                  // component={ArticleSearch}
                  render={(props) => (
                    <Page title="Article">
                      <ArticleSearch {...props} />
                    </Page>
                  )}
                />
                <Route
                  path="/support"
                  // component={Support}
                  render={(props) => (
                    <Page title="Support">
                      <Support {...props} />
                    </Page>
                  )}
                />
                <Route
                  // path={`/salesInvoiceReport/:CompanyID/no_of_copies=1/:invoice_type/:invoice_id/:PriceRounding/:BranchID/:print_template`}
                  path={`/salesInvoiceReport/*`}
                  // component={Support}
                  render={() => (
                    <Page title="Invoice_Report">
                      <InvoiceReport  />
                    </Page>
                  )}
                />
                <Route
                  path={`/invoiceReport/*`}
                  render={() => (
                    <Page title="Invoice_Report">
                      <InvoiceReport  />
                    </Page>
                  )}
                />
                <Route
                  // path={`/salesInvoiceReport/:CompanyID/no_of_copies=1/:invoice_type/:invoice_id/:PriceRounding/:BranchID/:print_template`}
                  path={`/landingPage/*`}
                  // component={Support}
                  render={() => (
                    <Page title="Landing Page">
                      <LandingPage />
                    </Page>
                  )}
                />
                <Route component={PageNotFound} />
              </Switch>
            </Router>
          </Suspense>
        );
      }}
    </CacheBuster>
  );
}

export default App;
