import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Slide,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  // makeStyles,
} from "@mui/material";
// import { motion } from "framer-motion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import swal from "sweetalert";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { styled } from "@mui/system";
// import Slider from "react-slick";
import "./invoiceReport.css";
import "./landingpage.css";
import "./landingAnimation.css";
import ProductCardCarosele from "./ProductCardCarosele";
import axios from "axios";
import { ACCOUNTS_API_URL, ACCOUNTS_FRONT_URL } from "../../settings";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

// const useStyles = makeStyles((theme) => ({
//   // Define a custom CSS class to target the ::before pseudo-element
//   removeBorderBefore: {
//     '& .MuiAccordionSummary-content': {
//       '&::before': {
//         borderBottom: 'none', // Remove the border bottom
//       },
//     },
//   },
// }));

const questionList = [
  {
    qs: "How Can Viknbooks Transform My Business?",
    as: "You Can Attend The Classes From Anywhere Across The World At Your Convenience.",
  },
  {
    qs: "How Can Viknbooks Help Me Manage My Supply Chain Effectively?",
    as: "You Can AtHow Can Viknbooks Transform My Business?tend The Classes From Anywhere Across The World At Your Convenience.",
  },
  {
    qs: "Is My Data Secure In Viknbooks?",
    as: "You Can Attend The Classes From Anywhere Across The World At Your Convenience.",
  },
  {
    qs: "What Features Should I Look For In An ERP System For My Industry?",
    as: "You Can Attend The Classes From Anywhere Across The World At Your Convenience.",
  },
  {
    qs: "How Can Viknbooks Enhance My Financial Management And Reporting?",
    as: "You Can Attend The Classes From Anywhere Across The World At Your Convenience.",
  },
  {
    qs: "What Benefits Can Viknbooks Offer For Remote Work And Accessibility?",
    as: "You Can Attend The Classes From Anywhere Across The World At Your Convenience.",
  },
  {
    qs: "How Viknbooks Can Optimize My Inventory Management?",
    as: "You Can Attend The Classes From Anywhere Across The World At Your Convenience.",
  },
  {
    qs: "What Level Of Customization Can I Expect From Viknbooks?",
    as: "You Can Attend The Classes From Anywhere Across The World At Your Convenience.",
  },
];

const newData = [
  {
    user: "Savad Farooque",
    heading: "UI/UX Designer",
    subHeading: "Fossa Solutions",
    description: `Rassasy has transformed the way we manage our restaurant. From handling reservations to tracking inventory, it's a complete game-changer. Our operations have become smoother, and we can now focus on delivering an exceptional dining experience to our customers.`,
  },
  {
    user: `Dennis jose`,
    heading: "Customer Support Executive",
    subHeading: "Blueberry",
    description: `Managing van sales used to be a logistical nightmare, but Faera has revolutionized the way we operate. With its efficient tools, we can now manage our on-the-go sales seamlessly. Faera's features have helped us increase our outreach and enhance customer interactions.`,
  },
];

let headers = new Headers();

headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
headers.append("Origin", "http://localhost:3002");

let isLogin = JSON.parse(localStorage.getItem("user"))

const LandingPage = () => {
  const history = useHistory()
  // const classes = useStyles();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    subject: "Vikn Books",
  });

  const [errors, setErrors] = useState({});
  const [elementWidth, setElementWidth] = useState(0);
  const [thirdElement, setThirdElement] = useState(0);
  const [alignment, setAlignment] = useState("");
  const [incNum, setIncNum] = useState(0);
  const [expanded, setExpanded] = useState("");
  const textWidth = useRef(null);
  const thirdWidthRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error when the user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const [currentIncNum, setCurrentIncNum] = useState(0);
  const [direction, setDirection] = useState("left");

  const handleNext = () => {
    setDirection("left");
    setCurrentIncNum((prevNum) => (prevNum + 1) % newData.length);
  };

  const handlePrev = () => {
    setDirection("right");
    setCurrentIncNum(
      (prevNum) => (prevNum - 1 + newData.length) % newData.length
    );
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const requestAdemo = () => {
    const element = document.getElementById("formContainer");
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check for empty fields and set errors if any
    const newErrors = {};
    for (const field in formData) {
      if (!formData[field].trim()) {
        newErrors[field] = "This field is required";
      }
    }
    setErrors(newErrors);
    let is_valid = Object.keys(newErrors).length === 0;
    if (is_valid) {
      // axios
      //   .post("http://127.0.0.1:8000/api/v1/web/contact/", {
      //     name: formData.name,
      //     subject: formData.subject,
      //     message: formData.message,
      //     phone: formData.phone,
      //     email: formData.email,
      //     subject: formData.subject,
      //   })
      axios({
        method: "post",
        url: `${ACCOUNTS_API_URL + `api/v1/web/contact/ `}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          name: formData.name,
          subject: formData.subject,
          message: formData.message,
          phone: formData.phone,
          email: formData.email,
          subject: formData.subject,
        },
      }).then(function (response) {
        console.log(response.data.StatusCode, "===response");
        if (response.data !== null && response.data !== undefined) {
          swal({
            title: "success",
            text: "successfully completed",
            icon: "success",
            button: true,
          });
          setFormData({
            ...formData,
            name: "",
            message: "",
            phone: "",
            email: "",
            subject: "",
          });
        }
      });
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Check for empty fields and set errors if any
  //   const newErrors = {};
  //   for (const field in formData) {
  //     if (!formData[field].trim()) {
  //       newErrors[field] = "This field is required";
  //     }
  //   }
  //   setErrors(newErrors);
  //   // If there are no errors, you can proceed with form submission
  //   if (Object.keys(newErrors).length === 0) {
  //     // Perform your form submission logic here
  //     console.log("Form submitted:", formData);

  //     fetch("http://127.0.0.1:8000/api/v1/web/contact/", {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: {
  //         name: formData.name,
  //         phone: formData.phone,
  //         email: formData.email,
  //         message: formData.message,
  //         subject: "Vikn Books",
  //       },
  //     })
  //       .then((data) => data.json())
  //       .then((data) => console.log(data))
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  // console.log(textWidth?.current?.offsetWidth);

  const signLog = function() {
    if (isLogin?.access) {
      history.push("dashboard/home")
    }else {
      window.open(
        `${ACCOUNTS_FRONT_URL}signin?service=viknbooks`,
        "_self"
      )
    }
  }

  useEffect(() => {
    const observe = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // console.log(entry);
        if (entry.isIntersecting) {
          entry.target.classList.add("textAnim");
        }
        // else {
        //   entry.target.classList.remove("rotate");
        // }
      });
    });

    const hiddenElements = document.querySelectorAll(".animText");
    hiddenElements.forEach((el) => observe.observe(el));
    setElementWidth(textWidth?.current?.offsetWidth);
    setThirdElement(thirdWidthRef?.current?.offsetWidth - 50);
    if (isLogin?.access) {
      history.push("dashboard/home")
    }
  }, []);

  return (
    <div className="h-100vh w-100vw">
 

      {/* <div class="sliper">
        <div class="slipers">
          <div id="slide-1">1</div>
          <div id="slide-2">2</div>
          <div id="slide-3">3</div>
          <div id="slide-4">4</div>
          <div id="slide-5">5</div>
        </div>
        <a href="#slide-1">1</a>
        <a href="#slide-2">2</a>
        <a href="#slide-3">3</a>
        <a href="#slide-4">4</a>
        <a href="#slide-5">5</a>
      </div> */}

      <div className="heading">
        <div className="d-flex justify-spaceBetween w-100">
          <img
            src="../images/LandingPage_Assets/Group3013.svg"
            alt="Logo"
            className="cursor-pointer"
          />

          <button
            className="headerButton d-block"
            onClick={() => signLog()}
          >
            Sign In
          </button>
        </div>
      </div>
      <div className="container" >
        {/* <Slider {...settings}>
          <div>
            <img src="https://picsum.photos/400/300" />
          </div>
          <div>
            <img src="https://picsum.photos/400/400" />
          </div>
          <div>
            <img src="https://picsum.photos/400/500" />
          </div>
          <div>
            <img src="https://picsum.photos/400/100" />
          </div>
        </Slider> */}

        {/* <ProductCardCarosele /> */}
      </div>
      <section className="firstSection h-100vh ">
        <div className="w-100 imageContainer relative">
          <img
            src="../images/LandingPage_Assets/Group3013.svg"
            alt="Logo"
            className="m-auto d-block mb-24 logoImg"
          />

          <div className="unPosFirst ">
            <img
              src="../images/LandingPage_Assets/Group 3494.svg"
              alt=""
              className="fsimg1"
            />
            <img
              src="../images/LandingPage_Assets/Group 3490.svg"
              alt=""
              className="fsimg2"
            />
          </div>
        </div>

        <div className="landingPageTitles relative">
          <h1
            ref={textWidth}
            className="line-height font-bungee text-regular font-darkBlue text-center font-23 mb-24 fsTitle"
          >
            Empowering Your Business with our ground breaking ERP Solutions
          </h1>
          <h2 className="text-center font-azeret text-regular font-14 mb-24 fsSubtitle ">
            One Platform, Diverse Industries.
          </h2>

          <div
            style={{ width: elementWidth + "px" }}
            className="unpositinedElements flex justify-between"
          >
            <div className="">
              <img
                src="../images/LandingPage_Assets/Group 3486.svg"
                alt=""
                className="fscImg1"
              />
              <img
                src="../images/LandingPage_Assets/Group 3233.svg"
                alt=""
                className="fscImg2"
              />
              <img
                src="../images/LandingPage_Assets/Group 3488.svg"
                alt=""
                className="fscImg3"
              />
              <img
                src="../images/LandingPage_Assets/Group 3485.svg"
                alt=""
                className="fscImg4"
              />
            </div>

            <div className="">
              <img
                src="../images/LandingPage_Assets/Group 3255.svg"
                alt=""
                className="fscImg5"
              />
              <img
                src="../images/LandingPage_Assets/Group 3487.svg"
                alt=""
                className="fscImg6"
              />
              <img
                src="../images/LandingPage_Assets/Group 3419.svg"
                alt=""
                className="fscImg7"
              />
            </div>
          </div>
        </div>

        <div className="w-100">
          <button
            className="lButton m-auto d-block font-14"
            onClick={() => requestAdemo()}
          >
            Request A Demo{" "}
          </button>
        </div>
        <div
          className="logoContainer"
          style={{
            backgroundImage: `url("../images/LandingPage_Assets/Path 2712.svg")`,
            zIndex: "-999",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <img
            src="../images/LandingPage_Assets/50132571.svg"
            alt="Landing_Image"
            className="Landing_Image"
          />
        </div>
      </section>

      <section id="formContainer" className="bg-lightBlue secondSection">
        <div className="ssholder">
          <div className="ssFirstContner">
            <h2 className="line-height font-bungee text-regular font-darkBlue text-center font-23 mb-14 ssText animText">
              Need A Demo?
            </h2>
            <h2 className="text-center font-azeret text-regular font-14 ssText">
              Tell Us Your Contacts And
            </h2>
            <h2 className="text-center font-azeret text-regular font-14 mb-24 ssText">
              We Will Get Back To You Jiffy.
            </h2>

            <form onSubmit={handleSubmit}>
              {errors.name && <span className="error-text">{errors.name}</span>}
              <input
                className={`d-block w-100 inputBox font-14 text-regular bg-white ${
                  errors.name && "inputBoxErrortext"
                }`}
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={(e) => handleChange(e)}
              />

              {errors.phone && (
                <span className="error-text">{errors.phone}</span>
              )}
              <input
                className={`d-block w-100 inputBox font-14 text-regular bg-white ${
                  errors.phone && "inputBoxErrortext"
                }`}
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={(e) => handleChange(e)}
              />

              {errors.email && (
                <span className="error-text">{errors.email}</span>
              )}
              <input
                className={`d-block w-100 inputBox font-14 text-regular bg-white ${
                  errors.email && "inputBoxErrortext"
                }`}
                type="text"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={(e) => handleChange(e)}
              />

              {errors.message && (
                <span className="error-text">{errors.message}</span>
              )}
              <textarea
                className={`d-block w-100 inputBox font-14 text-regular bg-white ${
                  errors.message && "inputBoxErrortext"
                }`}
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={(e) => handleChange(e)}
                rows="4"
              />

              <button
                className="lButton d-block w-100 font-14 formButton"
                type="submit"
              >
                Send
              </button>
            </form>
          </div>
          <div className="d-hidden formImageContainer">
            <img
              src="../images/LandingPage_Assets/Group 4105.svg"
              alt="fera"
              className="formImage"
            />
          </div>
        </div>
      </section>

      <section className="thirdSection bg-black">
        <div className="tsholder">
          <div className="chooseBox">
            <h2 className="line-height font-white font-bungee font-23 text-center animText">
              Why Choose
            </h2>
            <h1 className="line-height font-white font-bungee font-31 text-center animText">
              Viknbooks?
            </h1>
            <p className="font-lightBlue font-azeret font-14 text-center">
              Can't make up your mind?
            </p>
            <p className="font-lightBlue font-azeret font-14 text-center">
              Here are some things you should know.
            </p>
          </div>

          <div className="d-flex justify-center chooseOptionContainer">
            <div className="chooseOption">
              <div className="chooseCard">
                <div className="d-flex justify-center align-center mb-24">
                  <img
                    src="../images/LandingPage_Assets/gears.svg"
                    alt="gear"
                    className="mr-12"
                  ></img>
                  <p className="font-white font-azeret font-14 text-semibold">
                    Enhanced Efficiency
                  </p>
                </div>
                <p className="font-white font-azeret font-14 text-center">
                  Embrace automation and reduce manual tasks.
                </p>
              </div>

              <div className="chooseCard">
                <div className="d-flex justify-center align-center mb-24">
                  <img
                    src="../images/LandingPage_Assets/ruler-pencil-solid.svg"
                    alt="gear"
                    className="mr-12"
                  ></img>
                  <p className="font-white font-azeret font-14 text-semibold">
                    Precision and Accuracy
                  </p>
                </div>
                <p className="font-white font-azeret font-14 text-center">
                  Minimize errors, make data-driven decisions.
                </p>
              </div>

              <div className="chooseCard">
                <div className="d-flex justify-center align-center mb-24">
                  <img
                    src="../images/LandingPage_Assets/development.svg"
                    alt="gear"
                    className="mr-12"
                  ></img>
                  <p className="font-white font-azeret font-14 text-semibold">
                    Scalable Growth
                  </p>
                </div>
                <p className="font-white font-azeret font-14 text-center">
                  Viknbooks grows with your business, adapting to your changing
                  needs
                </p>
              </div>

              <div className="chooseCard">
                <div className="d-flex justify-center align-center mb-24">
                  <img
                    src="../images/LandingPage_Assets/people.svg"
                    alt="gear"
                    className="mr-12"
                  ></img>
                  <p className="font-white font-azeret font-14 text-semibold">
                    Customer-Centric Approach
                  </p>
                </div>
                <p className="font-white font-azeret font-14 text-center">
                  Serve your customers better with smoother operations.
                </p>
              </div>
            </div>
          </div>

          <div className="onlyMobile d-flex justify-center">
            <button
              className="lButton bg-blue font-14"
              onClick={() => requestAdemo()}
            >
              Request A Demo
            </button>
          </div>
        </div>
      </section>

      <section className="fourthSection">
        <div className="mb-24">
          <h2 className="line-height font-bungee text-center font-23 font-darkBlue fsText animText">
            Some key
          </h2>
          <h1 className="line-height font-bungee text-center font-31 font-darkBlue mb-14 fsText animText">
            Features?
          </h1>
          <p className="font-azeret font-14 text-center fsText">
            Still can't make up your mind?
          </p>
          <p className="font-azeret font-14 text-center fsText">
            Here are some more reasons to choose us!
          </p>
        </div>

        {/* <div className="featureSliderWrapper"> */}
        <div className="d-flex featureSlider ">
          <div className="featureCard bg-lightBlue">
            <div className="d-flex justify-center">
              <img src="../images/LandingPage_Assets/Group 3500.svg" alt="" />
            </div>
            <p className="font-azeret text-semibold text-center font-14 mb-14">
              Easy Restaurant Management
            </p>
            <p className="font-azeret font-14 text-center">
              From reservations to inventory, Rassasy optimizes your
              restaurant's workflow.
            </p>
          </div>

          <div className="featureCard bg-lightBlue">
            <div className="d-flex justify-center">
              <img src="../images/LandingPage_Assets/Group 3517.svg" alt="" />
            </div>
            <p className="font-azeret text-semibold text-center font-14 mb-14">
              Easy Restaurant Management
            </p>
            <p className="font-azeret font-14 text-center">
              From reservations to inventory, Rassasy optimizes your
              restaurant's workflow.
            </p>
          </div>

          <div className="featureCard bg-lightBlue">
            <div className="d-flex justify-center">
              <img src="../images/LandingPage_Assets/Group 4089.svg" alt="" />
            </div>
            <p className="font-azeret text-semibold text-center font-14 mb-14">
              Easy Restaurant Management
            </p>
            <p className="font-azeret font-14 text-center">
              From reservations to inventory, Rassasy optimizes your
              restaurant's workflow.
            </p>
          </div>
          {/* </div> */}
        </div>

        <div className="onlyMobile d-flex justify-center">
          <button
            className="lButton bg-blue font-14"
            onClick={() => requestAdemo()}
          >
            Request A Demo
          </button>
        </div>
      </section>

      <section className="fifthSection bg-snowWhite">
        <div className="fsholder">
          <div className="fifthSecTitle">
            <h1 className="line-height font-bungee text-center font-23 mb-14 fsText animText">
              Testimonials
            </h1>
            <p className="font-azeret font-14 text-center mb-24 fsText">
              See what our customers have to say about us.
            </p>
          </div>

          <div className="testimoniSlider">
            <div className="testimoniCard bg-lightBlue relative">
              <img
                src="../images/LandingPage_Assets/Group 4090.svg"
                alt=""
                className="comaImage"
              />
              <div className="testmoniesContainer">
                <p className="font-azeret font-14 mb-14 font-blue2">
                  Rassasy has transformed the way we manage our restaurant. From
                  handling reservations to tracking inventory, it's a complete
                  game-changer. Our operations have become smoother, and we can
                  now focus on delivering an exceptional dining experience to
                  our customers.
                </p>
                <div className="tstContainer">
                  <p className="font-azeret font-14 ">Savad Farooque</p>
                  <p className="font-azeret font-14 font-blue">
                    UI/UX Designer
                  </p>
                  <p className="font-azeret font-14">Fossa Solutions</p>
                </div>
              </div>
            </div>

            <div className="testimoniCard bg-lightBlue relative">
              <img
                src="../images/LandingPage_Assets/Group 4090.svg"
                alt=""
                className="comaImage"
              />
              <div className="testmoniesContainer">
                <p className="font-azeret font-14 font-blue2">
                  Managing van sales used to be a logistical nightmare, but
                  Faera has revolutionized the way we operate. With its
                  efficient tools, we can now manage our on-the-go sales
                  seamlessly. Faera's features have helped us increase our
                  outreach and enhance customer interactions.
                </p>
                <div className="tstContainer">
                  <p className="font-azeret font-14 ">Dennis jose</p>
                  <p className="font-azeret font-14 font-blue2">
                    Customer Support Executive{" "}
                  </p>
                  <p className="font-azeret font-14">Blueberry</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mobailCurosel">
            <Box sx={{ mt: "75px", position: "relative" }}>
              <Slide
                direction={direction}
                in={true}
                timeout={500}
                key={currentIncNum}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Box>
                <Box
                  sx={{ display: "flex", justifyContent: "center", mb: "44px" }}
                >
                  <img
                    src={newData[currentIncNum].image}
                    alt="somea"
                    style={{ width: "222px", height: "280px" }}
                  />
                </Box>

                <Box sx={{ mb: "67px" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#8D8D8D",
                      textAlign: "center",
                      mb: "29px",
                    }}
                  >
                    WHAT'S NEW
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {newData[currentIncNum].heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: newData[currentIncNum].color || "#006097",
                      textAlign: "center",
                    }}
                  >
                    {newData[currentIncNum].subHeading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      color: "#B1B1B1",
                      textAlign: "center",
                      width: "178px",
                      mx: "auto",
                    }}
                    paragraph
                  >
                    {newData[currentIncNum].description}
                  </Typography>
                </Box>

              </Box> */}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <div className="testimoniCard bg-lightBlue relative">
                    <img
                      src="../images/LandingPage_Assets/Group 4090.svg"
                      alt=""
                      className="comaImage"
                    />
                    <div className="testmoniesContainer">
                      <p className="font-azeret font-14 font-blue2">
                        {newData[currentIncNum].description}
                      </p>
                      <div className="tstContainer">
                        <p className="font-azeret font-14 ">
                          {newData[currentIncNum].user}
                        </p>
                        <p className="font-azeret font-14 font-blue2">
                          {newData[currentIncNum].heading}
                        </p>
                        <p className="font-azeret font-14">
                          {newData[currentIncNum].subHeading}
                        </p>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  onClick={handlePrev}
                  sx={{
                    backgroundColor: "#002944", // Background color
                    color: "#fff", // Text color
                    height: "56px !important",
                    minWidth: "56px !important",
                    borderRadius: "30px",
                    "&:hover": {
                      backgroundColor: "#002944", // Background color on hover
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#002944", // Background color when disabled
                    },
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </Button>

                <StyledToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                  sx={{ alignItems: "center" }}
                  mycolor={newData[incNum]?.color ? "#CEE1EF" : "#0C8DFF"}
                >
                  {newData.map((i, key) => (
                    <ToggleButton
                      key={key + 1}
                      value={i.heading}
                      onClick={() => {
                        setIncNum(key);
                        setCurrentIncNum(key);
                      }}
                      aria-label="centered"
                      selected={incNum === key}
                      sx={{
                        height: "7.88px",
                        width: "7.88px",
                        margin: "1.5px !important",
                      }}
                    >
                      <CircleIcon
                        style={{
                          fontSize: "14px",
                          height: "7.88px",
                          width: "7.88px",
                        }}
                      />
                    </ToggleButton>
                  ))}
                </StyledToggleButtonGroup>

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    backgroundColor: "#002944", // Background color
                    color: "#fff", // Text color
                    height: "56px !important",
                    minWidth: "56px !important",
                    borderRadius: "30px",
                    "&:hover": {
                      backgroundColor: "#002944", // Background color on hover
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#002944", // Background color when disabled
                    },
                  }}
                >
                  <KeyboardArrowRightIcon />
                </Button>
              </Box>
            </Box>
          </div>

          <div className="onlyMobile d-flex justify-center">
            <button
              className="lButton bg-blue font-14"
              onClick={() => requestAdemo()}
            >
              Request A Demo
            </button>
          </div>
        </div>
      </section>

      <section className="sixthSection">
        <h1 className="line-height font-bungee text-center font-23 mb-14 animText">
          frequently asked questions!
        </h1>
        <div>
          {questionList.map((i, key) => (
            <Accordion
              // className={classes.removeBorderBefore}
              key={key + 1}
              sx={{
                backgroundColor: "#F0F9FF", // Background color
                borderRadius: "12px !important", // Border radius
                boxShadow: "none", // Box shadow
                margin: "20px 0px",
                padding: "10px 0px",
                border: "none",
                "&::before": {
                  content: "none", // Remove the ::before content
                },
                "& .MuiAccordionSummary-content": {
                  "&::before": {
                    borderBottom: "none", // Remove the border bottom
                  },
                },
              }}
              // expanded={expanded === 'panel'+i}
              // onChange={() => setExpanded('panel'+i)}
            >
              <AccordionSummary
                // expandIcon={expanded === 'panel'+i ? <AddCircleOutlineIcon /> : <DoNotDisturbOnOutlinedIcon/>}
                sx={{
                  backgroundColor: "#F0F9FF",
                  borderRadius: "12px !important", // Background color for the summary header
                  "&::before": {
                    content: "none", // Remove the ::before content
                  },
                  "& .MuiSvgIcon-root": {
                    // Styles for the expand icon
                    color: "#0C8DFF", // Icon color
                    margin: "auto 1px",
                  },
                  // '&.Mui-expanded': {
                  //   '&::before': {
                  //     content: 'none', // Remove the border bottom
                  //   },
                  // },
                }}
                expandIcon={<AddCircleOutlineIcon />}
                aria-controls={`panel${key + 1}a-content`}
                id={`panel${key + 1}a-header`}
              >
                <CircleIcon
                  style={{
                    color: "#001D30",
                    fontSize: "14px",
                    marginRight: "15px",
                  }}
                />
                <h2 className="font-azeret font-14 text-semibold">{i.qs}</h2>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "16px", // Padding for the details content
                  "& .MuiAccordion-root::before": {
                    height: "0px !important",
                  },
                }}
              >
                <p className="font-azeret font-14 font-steelBlue">{i.as}</p>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </section>

      <section className="seventhSection bg-darkBlue relative">
        <h1
          ref={thirdWidthRef}
          className="line-height font-bungee text-center font-23 mb-14 font-white animText"
        >
          Ready to Elevate Your Business?
        </h1>
        <p className="font-azeret font-lightBlue text-center mb-24 font-14 sSparagraph">
          Now that you got gist of it. come on and join us and take your
          business to an whole new level.
        </p>
        <div className="d-flex justify-center">
          <button
            className="lButton bg-blue font-azeret font-14"
            onClick={() => requestAdemo()}
          >
            Request A Demo
          </button>
        </div>

        <div
          className="unpositinedElements "
          style={{ width: thirdElement + "px" }}
        >
          <div>
            <img
              src="../images/LandingPage_Assets/Group 3486.svg"
              alt=""
              className="sscImg1"
            />
            <img
              src="../images/LandingPage_Assets/Group 3233.svg"
              alt=""
              className="sscImg2"
            />
            <img
              src="../images/LandingPage_Assets/Group 3488.svg"
              alt=""
              className="sscImg3"
            />
            <img
              src="../images/LandingPage_Assets/Group 3485.svg"
              alt=""
              className="sscImg4"
            />
          </div>

          <div>
            <img
              src="../images/LandingPage_Assets/Group 3255.svg"
              alt=""
              className="sscImg5"
            />
            <img
              src="../images/LandingPage_Assets/Group 3487.svg"
              alt=""
              className="sscImg6"
            />
            <img
              src="../images/LandingPage_Assets/Group 3419.svg"
              alt=""
              className="sscImg7"
            />
          </div>
        </div>
      </section>

      <footer>
        <p className="font-azeret text-medium font-white font-14 text-center mb-14">
          Our Socials
        </p>
        <ul className="d-flex justify-center">
          <li>
            <a href="https://www.facebook.com/vikncodesllp">
              <img
                src="../images/LandingPage_Assets/bxl-facebook-circle.svg"
                target="_blank"
                alt="facebook"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/vikncodes/?hl=en"
              target="_blank"
            >
              <img src="../images/LandingPage_Assets/Group 12.svg" alt="" />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/@viknbooks312" target="_blank">
              <img src="../images/LandingPage_Assets/Group 13.svg" alt="" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/ViknCodes" target="_blank">
              <img
                src="../images/LandingPage_Assets/circletwitter.svg"
                alt=""
              />
            </a>
          </li>
        </ul>
        <div className="d-flex justify-center my-35">
          <p className="font-azeret font-14 font-white">Home</p>
          <p className="font-azeret font-14 font-white mx-27">|</p>
          <p className="font-azeret font-14 font-white">Prices</p>
          <p className="font-azeret font-14 font-white mx-27">|</p>
          <p className="font-azeret font-14 font-white">Blogs</p>
        </div>

        <p className="font-azeret font-14 font-white font-light mb-14 text-center">
          © 2023 Vikn Codes LLP. All Rights Reserved. Viknbooks Is A Trademarks
          Of Vikn Codes LLP
        </p>
        <p className="font-azeret font-14 font-white text-center">
          Terms And Contitions
        </p>
        <p className="font-azeret font-14 font-white text-center">
          Privacy Policy
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, mycolor }) => ({
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: "none", // Background color of the ToggleButtonGroup
      borderRadius: "8px", // Border radius of the ToggleButtonGroup
    },
    "& .MuiToggleButton-root": {
      color: "#F1F1F1", // Text color of the ToggleButtons
      border: "none", // Remove border from ToggleButtons
      borderRadius: "30px",
      "&.Mui-selected": {
        // backgroundColor: '#007bff', // Background color of the selected ToggleButton
        color: mycolor, // Text color of the selected ToggleButton
        "&:hover": {
          backgroundColor: "none", // Background color of the selected ToggleButton on hover
          borderRadius: "30px",
        },
      },
      "&:hover": {
        backgroundColor: "none", // Background color of the ToggleButton on hover
        borderRadius: "30px",
      },
    },
  })
);
