import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
// import noimage from "../images/noimage.png";
import { Media_Url } from "../../settings";

function ProductCardCarosele({  }) {
  const Images = [
      {
          id: 0,
          imageSrc: "https://picsum.photos/501/501",
      },
      {
          id: 1,
          imageSrc: "https://picsum.photos/501/502",
      },
      {
          id: 2,
          imageSrc: "https://picsum.photos/501/503",
      },
      {
          id: 3,
          imageSrc: "https://picsum.photos/501/504",
      },
      {
          id: 4,
          imageSrc: "https://picsum.photos/501/505",
      },
  ];

  const sliderRef = useRef();

  const pause = () => {
    // console.log("pause");
    // sliderRef.current.slickPrev()
    sliderRef?.current?.slickPause();
    sliderRef?.current?.slickGoTo(0);
  };

  const plays = () => {
    // console.log("play");
    // sliderRef.current.slickNext()
    sliderRef?.current?.slickPlay();

    // sliderRef.current.slickGoTo(3);
  };

  const settings = {
    dots: true,
    arrows: true,
    speed: false,
    autoplaySpeed: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className='flex overflow-hidden justify-center'>
        <section  className='w-60'>
      <div className="card-conatiner">
      <Slider {...settings} ref={sliderRef} slickGoTo={0}>
        {/* {Images.length > 0 ? ( */}
          {Images.map((i, index) => (
            <div className="overflow-y-hidden aspect-square">
              {/* h-[15rem] sm:h-[12rem] */}
              <img
                // onMouseEnter={plays}
                // onMouseLeave={pause}
                classNamdotse=" "
                key={index}
                src={i.imageSrc}
                alt="Display"
              />
            </div>
          ))}
        {/* // ) : (
        //   <img src={noimage} alt="NoImage" />

        // )} */}
      </Slider>
      </div>
      </section>
      </div>
    </>
  );
}

export default ProductCardCarosele;
