import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  ACCOUNTS_FRONT_URL,
  ADMIN_FRONT_URL,
  BASE_URL,
  MEDIA_URL,
  PAYROLL_FRONT_URL,
  TASK_FRONT_URL,
  VIKNBOOKS_FRONT_URL,
} from "../settings";
import axios from "axios";
import store from "../store/store";

export function checkWordLength(word) {
  let str = new String(word);
  let length = str.length;
  if (length > 13) {
    return true;
  }
  return false;
}

function useOutsideAlerter(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log(false);
        // alert("You clicked outside of me!");
      }
      return false;
    }
  }, [ref]);
}

export function stringCamelCase(string) {
  var split_string = string.split(" ");

  let final_arr = [];
  let final_name = "";

  for (let index = 0; index < split_string.length; index++) {
    const element = split_string[index];
    let name = element.charAt(0).toUpperCase() + element.slice(1);
    console.log(name);
    if (final_name) {
      final_name += " " + String(name);
    } else {
      final_name = String(name);
    }
    final_arr.push(final_name);
  }
  var lastItem = final_arr.pop();

  return lastItem;
}
// truncate number of words by passing the paragraph and number
export function truncate(str, num = 12) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
// to check the list of values is present in the array
export const listChecker = (arr, target) =>
  target.every((v) => arr.includes(v));

export const getInitials = function (string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
// to get index value from a array by passing array by passing the list of values and number
export const get_loyaltyCustomer_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.LoyaltyCustomerID === id);
    // index = list.findIndex((i) => i.id === id);
  }
  return index;
};
// to get index value from a array by passing array by passing the list of values and number
export const get_index = (list, id) => {
  let index = null;
  if (list) {
    // index = list.findIndex((i) => console.log(i.id, "FIXING BUG", id));
    index = list.findIndex((i) => i.id === id);
  }
  return index;
};
export const get_Ledger_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.LedgerID === id);
  }
  return index;
};

export const get_Product_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.ProductID === id);
  }
  return index;
};

export const get_ProductCode_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.ProductCode === id);
  }
  return index;
};

export const get_Unit_index = (list, id) => {
  // console.log(list, "<<<<<<<<<<<>>>>>>>>>>>>>>>", id);
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.PriceListID === id);
  }
  return index;
};

export const get_Batch_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.BatchCode === id);
  }
  return index;
};

export const get_Tax_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.TaxID === id);
  }
  return index;
};

export const get_warehouse_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.WarehouseID === id);
  }
  return index;
};

export const get_unit_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.UnitID === id);
  }
  return index;
};
export const get_group_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.ProductGroupID === id);
  }
  return index;
};
export const get_category_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.ProductCategoryID === id);
  }
  return index;
};

export const get_priceCat_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.PriceCategoryID === id);
  }
  return index;
};

export const get_brand_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.BrandID === id);
  }
  return index;
};
export const get_active_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.activeId === id);
  }
  return index;
};
export const get_tax_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.TaxID === id);
  }
  return index;
};

export const get_route_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.RouteID === id);
  }
  return index;
};

export const get_value_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.value === id);
  }
  return index;
};

export const get_employee_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.EmployeeID === id);
  }
  return index;
};

export const get_party_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.PartyID === id);
  }
  return index;
};

export const get_priceCategory_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.PriceCategoryID === id);
  }
  return index;
};

export const get_branch_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.BranchID === id);
  }
  return index;
};
// convert datetime object to python date string
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const findDuplicate = (array1, array2) => {
  var duplicates = array1.filter((val) => array2.indexOf(val) !== -1);
  return duplicates;
};

// just pass the id to show the password
export const showPassword = (id) => {
  var x = document.getElementById(id);
  console.log(x);
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
};

// download Excel
export function download_excel(url) {
  var element = window.document.createElement("a");
  element.href = url;
  element.target = "_blank"; // pdf will open new tab
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

// date to string(today)
export function today_date() {
  var today = new Date();
  today.setDate(today.getDate());
  var Today = today.toISOString().substr(0, 10);
  return Today;
}
// from datetime object to str date onlly
export function dateTimeObj_to_date(dateObj) {
  dateObj.setDate(dateObj.getDate());
  // var date = dateObj.toISOString().substr(0, 10);
  var date = dateObj
    .toLocaleString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
  return date;
}

// date to string(today)
export function date_range(type, financial_FromDate, financial_ToDate) {
  var fromDate = "";
  var toDate = "";

  if (type === "This Month") {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    var fromDate = moment(firstDay).format("YYYY-MM-DD");
    var toDate = moment(lastDay).format("YYYY-MM-DD");
  } else if (type === "Last Month") {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m - 1, 1);
    var lastDay = new Date(y, m, 0);
    var fromDate = moment(firstDay).format("YYYY-MM-DD");
    var toDate = moment(lastDay).format("YYYY-MM-DD");
  } else if (type === "Today") {
    var today = new Date();
    today.setDate(today.getDate());
    var Today = today.toISOString().substr(0, 10);
    fromDate = Today;
    toDate = Today;
    firstDay = new Date(fromDate);
    lastDay = new Date(toDate);
  } else if (type === "This Week") {
    var curr = new Date(); // get current date
    let week = [];

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push(day);
    }
    fromDate = week[0];
    toDate = week.slice(-1)[0];
    firstDay = new Date(fromDate);
    lastDay = new Date(toDate);
  } else if (type === "Last 90 Days") {
    console.log("UVAIS T THACHANKATTIL HOUSE MYTIL PO");
    var today = new Date(); // get current date
    var last = -90;
    var firstDay = new Date(today.setDate(last));
    var lastDay = new Date();
    var fromDate = moment(firstDay).format("YYYY-MM-DD");
    var toDate = moment(lastDay).format("YYYY-MM-DD");
  } else if (type === "Quarterly") {
    var today = new Date(); // get current date
    today.setMonth(today.getMonth() - 4);
    var last = -90;
    // var firstDay = new Date(today.setDate(last));
    var firstDay = today;
    var lastDay = date_range("Last Month")[1];
    // lastDay.setMonth(lastDay.getMonth() - 1);
    var fromDate = moment(firstDay).format("YYYY-MM-DD");
    var toDate = moment(lastDay).format("YYYY-MM-DD");
  } else if (type === "This Year") {
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // var firstDay = new Date(date.getFullYear(), 0, 1);
    // var lastDay = new Date(new Date().getFullYear(), 11, 31);
    // var fromDate = moment(firstDay).format("YYYY-MM-DD");
    // var toDate = moment(lastDay).format("YYYY-MM-DD");

    // current year changed to financial year current
    console.log(financial_FromDate,"financial_FromDate",financial_ToDate,"financial_ToDate");
    var firstDay = new Date(financial_FromDate);
    var lastDay = new Date(financial_ToDate);
    console.log(firstDay,"firstDay", lastDay, "lastDay");
    var fromDate = financial_FromDate;
    var toDate = financial_ToDate;
  } else if (type === "Last Year") {
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // var firstDay = new Date(date.getFullYear() - 1, 0, 1);
    // var lastDay = new Date(new Date().getFullYear() - 1, 11, 31);
    // var fromDate = moment(firstDay).format("YYYY-MM-DD");
    // var toDate = moment(lastDay).format("YYYY-MM-DD");

    // last year changed to financial year last
    var firstDay = new Date(financial_FromDate);
    var lastDay = new Date(financial_ToDate);
    firstDay.setFullYear(firstDay.getFullYear() - 1);
    lastDay.setFullYear(lastDay.getFullYear() - 1);
    console.log(firstDay, lastDay, "lastDay");
    var fromDate = moment(firstDay).format("YYYY-MM-DD");
    var toDate = moment(lastDay).format("YYYY-MM-DD");
  } else {
    var firstDay = new Date();
    var lastDay = new Date();
    var fromDate = moment(firstDay).format("YYYY-MM-DD");
    var toDate = moment(lastDay).format("YYYY-MM-DD");
  }
  return [fromDate, toDate, firstDay, lastDay];
}
// to get age pass date of birth
export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
// to get avatar images just pass a value you will get a random image
export const getAvatar = (value = "1", imageType = "identicon", res = 46) => {
  const hashCode = (s) =>
    s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return Math.abs(a & a);
    }, 0);

  // Create an hash of the string
  const hash = hashCode(value);
  // Grab the actual image URL

  // return `https://avatars.dicebear.com/api/${imageType}/${hash}.svg`;
  return `https://www.gravatar.com/avatar/${hash}?s=${res}&d=${imageType}&r=PG&f=1`;
};

export const get_Transaction_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.TransactionTypesID === id);
  }
  return index;
};

// check image exists
export const imageExists = (image_url) => {
  var http = new XMLHttpRequest();
  http.open("GET", image_url, false);
  http.send();
  return http.status != 404;
};

export const dataURLtoFile = async (dataurl) => {
  let filename = dataurl.substring(
    dataurl.lastIndexOf("/") + 1,
    dataurl.length
  );

  let response = await fetch(dataurl);
  console.log(response);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  let fileobj = new File([data], filename, metadata);
  let file = [
    {
      originFileObj: fileobj,
      uid: "1",
      name: filename,
      url: dataurl,
    },
  ];
  return file;
};

// get date n days ahead date react js hook
export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  let Year = result.getFullYear();
  let Month = result.getMonth() + 1;
  let Day = result.getDate();
  Day = ("0" + Day).slice(-2);
  Month = ("0" + Month).slice(-2);
  result = String(Year) + "-" + String(Month) + "-" + String(Day);
  return result;
};

// get date n days behind date react js hook
export const reduceDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  let Year = result.getFullYear();
  let Month = result.getMonth() + 1;
  let Day = result.getDate();
  Day = ("0" + Day).slice(-2);
  Month = ("0" + Month).slice(-2);
  result = String(Year) + "-" + String(Month) + "-" + String(Day);
  return result;
};

export const get_fileName_fromPath = (file) => {
  return file.split("\\").pop().split("/").pop();
};

export const get_Month_full_name = (month) => {
  var months = [
    {
      name: "January",
      short: "Jan",
    },
    {
      name: "February",
      short: "Feb",
    },
    {
      name: "March",
      short: "Mar",
    },
    {
      name: "April",
      short: "Apr",
    },
    {
      name: "May",
      short: "May",
    },
    {
      name: "June",
      short: "Jun",
    },
    {
      name: "July",
      short: "July",
    },
    {
      name: "August",
      short: "Aug",
    },
    {
      name: "September",
      short: "Sep",
    },
    {
      name: "October",
      short: "Oct",
    },
    {
      name: "November",
      short: "Nov",
    },
    {
      name: "December",
      short: "Dec",
    },
  ];
  var result = months.filter((i) => i.short === month)[0].name;
  return result;
};

export const get_last_n_month_names = (val) => {
  console.log(val);
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var today = new Date();
  var d;
  var month;
  var ListMonth = [];
  for (var i = val; i > 0; i--) {
    d = new Date(today.getFullYear(), today.getMonth() - i + 1, 1);
    month = monthNames[d.getMonth()];
    console.log(month);
    ListMonth.push(month);
  }

  if (val === 2) {
    ListMonth = [];
    var No_of_days = getDaysInCurrentMonth();
    for (let j = 1; j <= No_of_days; j++) {
      ListMonth.push(j);
    }
  }
  if (val === 1) {
    var ListMonth = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Fiday",
      "Saturday",
    ];
  }
  return ListMonth;
};

export function getDaysInCurrentMonth() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export function getUrlParam(some_key) {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let value = params.some_key;
  return value;
}

export function truncate_decimal(num) {
  var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return with2Decimals;
}

export function get_currentTime() {
  let time = new Date().toLocaleTimeString();
  return time;
}

export function generateRandomNumbers(all_ids) {
  let randomNumber = Math.floor(1000000000 + Math.random() * 9000000000);
  if (all_ids.includes(randomNumber)) {
    generateRandomNumbers(all_ids);
  } else {
    return randomNumber;
  }
}

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const getPathToFile = async (data) => {
  let file = [];
  let file1 = [];
  if (data && !data.includes("/null")) {
    console.log(data);
    if (imageExists(data)) {
      file1 = await dataURLtoFile(MEDIA_URL + data);
      file = file1[0]["originFileObj"];
      console.log(file);
      console.log(file1);
    }
  }
  return [file, file1];
};

export function Encrypt(word, key = "share") {
  var CryptoJS = require("crypto-js");
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
}

export function Decrypt(word, key = "share") {
  var CryptoJS = require("crypto-js");
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  return JSON.parse(bytes);
}

export function getServiceUrl(service) {
  if (service === "viknbooks") {
    console.log("inside viknbooks");
    window.open(`${VIKNBOOKS_FRONT_URL}dashboard/home`, "_self");
  } else if (service === "payroll") {
    console.log("inside payroll", PAYROLL_FRONT_URL);
    window.open(`${PAYROLL_FRONT_URL}dashboard`, "_self");
  } else if (service === "task_manager") {
    console.log("inside task manager");
    window.open(`${TASK_FRONT_URL}`, "_self");
  } else if (service === "admin_panel") {
    window.open(`${ADMIN_FRONT_URL}`, "_self");
  } else {
    window.open(`${ACCOUNTS_FRONT_URL}`, "_self");
  }
}

export const get_unq_browserID = async (data) => {
  // const biri = require("biri");
  // const uniqueId = await biri();
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;
  uid += screen_info.height || "";
  uid += screen_info.width || "";
  uid += screen_info.pixelDepth || "";
  console.log(uid);
  return uid;
};

export function getUnqNo() {
  return Date.now();
}

export function getStringDate(date) {
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();
  date = yyyy + "-" + mm + "-" + dd;
  return date;
}

// this function in used for getting no of days between any date and today
export function getNoOfDays(date) {
  var today = new Date();
  today = getStringDate(today);
  var diff = Math.floor((Date.parse(today) - Date.parse(date)) / 86400000);
  return diff;
}

export function formatNumber(number, price_round = 2) {
  var formatted = Number(number).toLocaleString("hi-IN", {
    minimumFractionDigits: price_round,
  });
  return formatted;
}
export function getServerID() {
  let state = store.getState()
  let serverId = 0
  if (state.companyUrl.companyUrl=="https://saudiserver.viknbooks.com"){
    serverId = 1
  }
  return serverId;
}

export function compressImage (file,message,MAX_WIDTH,MAX_HEIGHT,MIME_TYPE,QUALITY)  {
  return new Promise((resolve, reject) => {
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = () => {
      URL.revokeObjectURL(blobURL);
      message.error("Cannot load image");
      reject(new Error("Cannot load image"));
    };
    img.onload = () => {
      URL.revokeObjectURL(blobURL);
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, MIME_TYPE, QUALITY);
    };
  });
};

const calculateSize = (img, maxWidth, maxHeight) => {
  let width = img.width;
  let height = img.height;
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
};







