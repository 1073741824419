import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("Filter")
  ? JSON.parse(localStorage.getItem("Filter"))
  : { is_pending: true,is_invoiced:false, is_cancelled:false, is_user:false };

const sales_filter = createSlice({
  name: "Filter",
  initialState,
  reducers: {
    FilterSuccess: (state, action) => {
      console.log("here we are pppppppppppppppp");
      console.log(action.payload);
      // state.sales_order_pending = action.payload.sales_order_pending;
      // state.sales_order_invoiced = action.payload.sales_order_invoiced;
      localStorage.setItem("Filter", JSON.stringify(action.payload));
    },
    FilterLogoutSuccess: (state, action) => {
      state = initialState;

      localStorage.removeItem("Filter");
    },
  },
});

export const {
  FilterSuccess,
  FilterLogoutSuccess,
} = sales_filter.actions;

// export const selectCompanyName = (state) => state.submitMode.CompanyName;

export default sales_filter.reducer;
