import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("financialYear")
  ? JSON.parse(localStorage.getItem("financialYear"))
  : {
      financial_FromDate: "",
      financial_ToDate: "",
    };

const financialYearSlice = createSlice({
  name: "financialYear",
  initialState,
  reducers: {
    financialYearSuccess: (state, action) => {
      state.financial_FromDate = action.payload.financial_FromDate;
      state.financial_ToDate = action.payload.financial_ToDate;

      localStorage.setItem("financialYear", JSON.stringify(action.payload));
    },
    financialYearLogoutSuccess: (state, action) => {
      state.financial_FromDate = null;
      state.financial_ToDate = null;

      localStorage.removeItem("financialYear");
    },
  },
});

export const { financialYearSuccess, financialYearLogoutSuccess } =
  financialYearSlice.actions;

export const selectFinancialYearFromDate = (state) =>
  state.financialYear.financial_FromDate;
export const selectFinancialYearToDate = (state) =>
  state.financialYear.financial_ToDate;

export default financialYearSlice.reducer;
