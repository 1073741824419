import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("branchSettings")
    ? JSON.parse(localStorage.getItem("branchSettings"))
    : {
        EnableBranch: false,
        suppliersForAllBranches: false,
        customersForAllBranches: false,
        productsForAllBranches: false,
    };

const branchSettingsSlice = createSlice({
    name: "branchSettings",
    initialState,
    reducers: {
        branchSettingsSuccess: (state, action) => {
            state.EnableBranch = action.payload.EnableBranch;
            state.suppliersForAllBranches = action.payload.suppliersForAllBranches;
            state.customersForAllBranches = action.payload.customersForAllBranches;
            state.productsForAllBranches = action.payload.productsForAllBranches;
            state.BusinessTypeName = action.payload.BusinessTypeName;

            localStorage.setItem("branchSettings", JSON.stringify(action.payload));
        },
        branchSettingsLogoutSuccess: (state, action) => {
            state.EnableBranch = null;
            state.suppliersForAllBranches = null;
            state.customersForAllBranches = null;
            state.productsForAllBranches = null;
            state.BusinessTypeName = null;

            localStorage.removeItem("branchSettings");
        },
    },
});

export const { branchSettingsSuccess, branchSettingsLogoutSuccess } =
    branchSettingsSlice.actions;

// export const selectFinancialYearFromDate = (state) =>
//   state.branchSettings.customerForAllBranches;
// export const selectFinancialYeasrToDate = (state) =>
//   state.branchSettings.suppliersForAllBranches;

export default branchSettingsSlice.reducer;
